export default {
  methods: {
    back: function () {
      var app = this.$app;
      var $$ = this.$$;
      var router = this.$router;
      app.panel.enableSwipe('left');
      router.back();
    }
  },
  on: {
    pageInit: function () {
      var app = this.$app;
      var $$ = this.$$;
      var router = this.$router;
      app.panel.disableSwipe('left');
      app.preloader.show('blue');
      app.request.get('https://tedeco.kz/equipment/api/notifi.php', { id: app.data.user.id }, function (notifi) {
        app.preloader.hide();
        var ht = '';
        if (notifi.length >= 1) {
          for (var s = 0; s < notifi.length; s++)
            ht += '<div class="card" data-id="' + notifi[s].id_zaya + '" name="' + notifi[s].id_bd + '"><div name="' + notifi[s].id_bd + '" data-id="' + notifi[s].id_zaya + '" class="card-header">ID аппарата <font color="red">' + notifi[s].id_bd + '</font></div>' + '<div name="' + notifi[s].id_bd + '" data-id="' + notifi[s].id_zaya + '" class="card-content card-content-padding">' + notifi[s].fio + ' изменил статус <font color="blue">' + notifi[s].status + '</font></div>' + '<div name="' + notifi[s].id_bd + '" data-id="' + notifi[s].id_zaya + '" class="card-footer">' + notifi[s].data + '</div></div>';
          $$('#virt_notifi').html(ht);
        } else
          $$('#virt_notifi').html('У вас ничего нету');
      }, function () {
        app.preloader.hide();
        app.dialog.alert('Нет подключения к интернету');
      }, 'json');
      $$('#virt_notifi').on('click', 'div,a', function () {
        var id = $$(this).attr('data-id');
        var id_db = $$(this).attr('name');
        if (id != null) {
          router.navigate('/history/?id=' + id + '&stat=active&id_db=' + id_db);
        }
      });
    }
  },
  id: 'b0e9bd514d',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== 'undefined' && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else
            return val;
        } else
          return '';
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '\n\t<div class="page" data-name="notifi">\n\t  <!-- Top Navbar -->\n\t  <div class="navbar">\n\t\t<div class="navbar-inner">\n\t\t  <div class="left"  @Click="back">\n            <a href="#" class="link text-color-white">\n              <i class="icon icon-back"></i><span class="ios-only">Назад</span>\n            </a>\n        </div>\n\t\t  <div class="title sliding">Уведомления</div>\n\t\t</div>\n\t  </div>\n\t  <!-- Scrollable page content-->\n\t  <div class="page-content">\n\t\t  <div class="block" id="virt_notifi">\n\n\t\t  </div>\n\t  </div>\n\t</div>\n  ';
      return r;
    }(this);
  },
  styleScoped: false
};
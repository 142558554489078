export default {
  methods: {
    voiti: function () {
      var app = this.$app;
      var $$ = this.$$;
      var router = this.$router;
      app.preloader.show('blue');
      app.request.post('https://tedeco.kz/equipment/api/vhod.php', {
        log: $$('#log').val(),
        pass: $$('#pass').val(),
        vhod: 'app'
      }, function (otvet) {
        app.preloader.hide();
        if (otvet[0].message == 'ok') {
          app.data.user.id = otvet[0].id;
          app.form.storeFormData('user', { 'token': otvet[0].token });
          $$('#user').text(otvet[0].fam + ' ' + otvet[0].name);
          app.data.user.roles = otvet[0].roles;
          app.data.user.glav = otvet[0].glav;
          router.navigate('/', { reloadAll: true });
        } else
          app.dialog.alert('Не правильный логин или пароль!');
      }, function () {
        app.preloader.hide();
        app.dialog.alert('Нет подключения к интернету');
      }, 'json');
    }
  },
  on: {
    pageInit: function () {
      var app = this.$app;
      var $$ = this.$$;
      var router = this.$router;
      var log = JSON.parse(localStorage.getItem('f7form-user'));
      if (log != null) {
        var mail = log.mail;
        var token = log.token;
        app.preloader.show('blue');
        app.request.post('https://tedeco.kz/equipment/api/vhod.php', {
          token: token,
          vhod: 'app'
        }, function (data) {
          app.preloader.hide();
          if (data[0].message == 'ok') {
            app.data.user.id = data[0].id;
            $$('#user').text(data[0].fam + ' ' + data[0].name);
            app.data.user.roles = data[0].roles;
            app.data.user.glav = data[0].glav;
            router.navigate('/', { reloadAll: true });
          } else {
            app.form.removeFormData('user');
          }
        }, 'json');
      }
    }
  },
  id: 'ec0a3ea274',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== 'undefined' && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else
            return val;
        } else
          return '';
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '\r\n  <div class="page">\r\n    <div class="navbar" >\r\n      <div class="navbar-inner sliding">\r\n       <div class="title">Equipment</div>\r\n      </div>\r\n    </div>\r\n  \r\n  \r\n  \t    <div class="toolbar tabbar toolbar-bottom">\r\n  <div style="width:100%;height:100%;display: -webkit-flex;\r\n  display: flex;\r\n  align-items: center;\r\n  justify-content: center;" class="button" @Click="voiti"><font size="3%" color="white">ВОЙТИ</font></div>\r\n</div>\r\n\r\n\r\n  <div class="page-content">\r\n  <div class="card" style="--f7-card-border-radius: 8px;">\r\n\r\n <div class="card-content">\r\n  <div class="login-screen-title"><font style="font-size: 70%;" >Авторизация</div>\r\n      <div class="list">\r\n        <ul>\r\n          <li class="item-content item-input item-input-outline">\r\n\t\t  <div class="item-inner">\r\n              <div class="item-title item-floating-label" ><i class="icon f7-icons color-blue size-22">person</i>логин</div>\r\n              <div class="item-input-wrap">\r\n                <input id="log" type="text" placeholder="ваш логин">\r\n              </div>\r\n            </div>\r\n          </li>\r\n          <li class="item-content item-input item-input-outline">\r\n            <div class="item-inner">\r\n              <div class="item-title item-floating-label"><i class="icon f7-icons color-blue size-22">unlock</i> пароль</div>\r\n              <div class="item-input-wrap">\r\n                <input id="pass" type="password" placeholder="******">\r\n              </div>\r\n            </div>\r\n          </li>\r\n<br>\r\n        </ul>\r\n\r\n\t\t  </div>\r\n\t\t</div>\r\n\r\n      </div>\r\n  \r\n\t\t\t\r\n\t\t\t\r\n        </div>\r\n\t\r\n\t\r\n    </div>\r\n';
      return r;
    }(this);
  },
  styleScoped: false
};
export default {
  methods: {
    backvodo: function () {
      var app = this.$app;
      var $$ = this.$$;
      var router = this.$router;
      app.panel.enableSwipe('left');
      router.back();
      if (app.data.url == '/') {
        app.methods.home.users();
        app.methods.home.timers();
      }
    }
  },
  on: {
    pageInit: function () {
      var app = this.$app;
      var $$ = this.$$;
      var router = this.$router;
      app.panel.disableSwipe('left');
      app.preloader.show('blue');
      app.request.post('https://tedeco.kz/equipment/api/monitoring.php', {
        zapros: 'list',
        id: app.data.user.id
      }, function (notifi) {
        app.preloader.hide();
        var ht = '';
        if (notifi.length >= 1) {
          for (var s = 0; s < notifi.length; s++) {
            var color = '';
            if (parseFloat(notifi[s].n3) <= 5)
              color = 'bg-color-green';
            else if (parseFloat(notifi[s].n3) > 5 && parseFloat(notifi[s].n3) <= 8)
              color = 'bg-color-yellow';
            else if (parseFloat(notifi[s].n3) > 8)
              color = 'bg-color-red';
            ht += '<li>' + '<a id="' + notifi[s].id + '" name="' + notifi[s].name + '" class="item-link item-content ' + color + '">' + '<div class="item-inner">' + '<div class="item-title">' + notifi[s].name + '<div class="item-footer">' + notifi[s].data + '</div></div>' + '<div class="item-after">' + notifi[s].n3 + '</div>' + '</div>' + '</a>' + '</li>';
          }
          $$('#virt_vodo').html(ht);
        } else
          $$('#virt_vodo').html('У вас ничего нету');
      }, function () {
        app.preloader.hide();
        app.dialog.alert('Нет подключения к интернету');
      }, 'json');
      $$('#virt_vodo').on('click', 'a', function () {
        var id = $$(this).attr('id');
        var name = $$(this).attr('name');
        router.navigate('/vodo_his/?name=' + name + '&bd=' + id);
      });
    }
  },
  id: '15357d5a1c',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== 'undefined' && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else
            return val;
        } else
          return '';
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '\n\t<div class="page" data-name="vodo">\n\t  <!-- Top Navbar -->\n\t  <div class="navbar">\n\t\t<div class="navbar-inner">\n\t\t  <div class="left"  @Click="backvodo">\n            <a href="#" class="link text-color-white">\n              <i class="icon icon-back"></i><span class="ios-only">Назад</span>\n            </a>\n        </div>\n\t\t  <div class="title sliding">Мониторинг сис.водоочистки</div>\n\t\t</div>\n\t  </div>\n\t  <!-- Scrollable page content-->\n\t  <div class="page-content">\n\t\t  <div class="list inset">\n\t\t\t<ul  id="virt_vodo">\n\n\t\t\t\t</ul>\n\t\t  </div>\n\t  </div>\n\t</div>\n  ';
      return r;
    }(this);
  },
  styleScoped: false
};
export default {
  methods: {
    backs: function () {
      var app = this.$f7;
      var $$ = this.$$;
      var router = this.$router;
      router.navigate('/', { reloadAll: true });
    }
  },
  on: {
    pageInit: function () {
      var app = this.$f7;
      var $$ = this.$$;
      var router = this.$router;
      app.preloader.show('blue');
      app.request.get('https://tedeco.kz/equipment/api/obr.php', {
        id: app.data.user.id,
        roles: app.data.user.roles,
        zapros: 'spisok'
      }, function (notifi) {
        app.preloader.hide();
        var ht = '';
        if (notifi[0].message == 'ok') {
          for (var s = 0; s < notifi.length; s++)
            ht += '<div data-id ="' + notifi[s].id + '" name="' + notifi[s].id_db + '" value="' + notifi[s].company + ', ' + notifi[s].place + '" class="card"><div class="card-header" name="' + notifi[s].id_db + '" value="' + notifi[s].company + ', ' + notifi[s].place + '" data-id ="' + notifi[s].id + '">Заявка \u2116 ' + notifi[s].id + '</div>' + '<div data-id ="' + notifi[s].id + '" value="' + notifi[s].company + ', ' + notifi[s].place + '"name="' + notifi[s].id_db + '"  class="card-content card-content-padding">' + notifi[s].name + '<br>id:' + notifi[s].id_db + '<br>Статус:' + notifi[s].status + '<br>' + notifi[s].company + ', ' + notifi[s].place + '</div>' + '<div data-id ="' + notifi[s].id + '" value="' + notifi[s].company + ', ' + notifi[s].place + '" name="' + notifi[s].id_db + '"  class="card-footer">' + notifi[s].data + '</div></div>';
        }
        if (ht == '')
          $$('#virtual-list').html('Ничего нет');
        else
          $$('#virtual-list').html(ht);
      }, function () {
        app.preloader.hide();
      }, 'json');
      $$('#virtual-list').on('click', 'div', function () {
        var id = $$(this).attr('data-id');
        var id_db = $$(this).attr('name');
        var place = $$(this).attr('value');
        router.navigate('/history/?id=' + id + '&stat=forum&id_db=' + id_db + '&place=' + place);
      });
    }
  },
  id: 'ebb228ff79',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== 'undefined' && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else
            return val;
        } else
          return '';
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '\r\n<div class="page">\r\n  <div class="navbar">\r\n    <div class="navbar-inner sliding">\r\n      <div class="left">\r\n        <a class="link nazad" @Click="backs">\r\n          <i  style="color:#073974;" class="icon icon-back"></i>\r\n        </a>\r\n      </div>\r\n      <div class="title"><font size="3%">Форум</font></div>\r\n    </div>\r\n  </div>\r\n  <div class="page-content">\r\n\r\n<div id="virtual-list">\r\n\r\n\t</div>\r\n\t\t  \r\n    </div>\r\n\r\n</div>\r\n';
      return r;
    }(this);
  },
  styleScoped: false
};

      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      export default {
  methods: {
    backs: function () {
      var app = this.$f7;
      var $$ = this.$$;
      var router = this.$router;
      router.back({ force: true });
    }
  },
  on: {
    pageInit: function () {
      var app = this.$f7;
      var $$ = this.$$;
      var router = this.$router;
      var chat_id = '';
      app.preloader.show('blue');
      chat_id = this.$route.query.id;
      app.request.get('https://tedeco.kz/equipment/api/message.php', { id: chat_id }, function (messagess) {
        app.preloader.hide();
        if (messagess.length > 0) {
          for (var s = 0; s < messagess.length; s++)
            messages.addMessage({
              text: messagess[s].text,
              type: messagess[s].type,
              name: messagess[s].name,
              avatar: messagess[s].avatar,
              textFooter: messagess[s].footer,
              Footer: ' '
            });
          messages.hideTyping();
          app.preloader.hide();
        }
      }, function () {
        app.preloader.hide();
        app.dialog.alert('Нет подключения к интернету');
      }, 'json');
      var messages = app.messages.create({
        el: '.messages',
        scrollMessages: true,
        scrollMessagesOnEdge: false,
        newMessagesFirst: false,
        firstMessageRule: function (message, previousMessage, nextMessage) {
          if (message.isTitle)
            return false;
          if (!previousMessage || previousMessage.type !== message.type || previousMessage.name !== message.name)
            return true;
          return false;
        },
        lastMessageRule: function (message, previousMessage, nextMessage) {
          if (message.isTitle)
            return false;
          if (!nextMessage || nextMessage.type !== message.type || nextMessage.name !== message.name)
            return true;
          return false;
        },
        tailMessageRule: function (message, previousMessage, nextMessage) {
          if (message.isTitle)
            return false;
          if (!nextMessage || nextMessage.type !== message.type || nextMessage.name !== message.name)
            return true;
          return false;
        }
      });
      var messagebar = app.messagebar.create({ el: '.messagebar' });
      var responseInProgress = false;
      $$('.send-link').on('click', function () {
        app.preloader.show('blue');
        var text = messagebar.getValue().replace(/\n/g, '<br>').trim();
        app.request.get('https://tedeco.kz/equipment/api/add_mess.php', {
          id: chat_id,
          type: 'sent',
          text: text,
          name: app.data.user.im
        }, function (data2) {
          if (data2[0].message == 'ok') {
            app.preloader.hide();
            if (!text.length)
              return;
            messagebar.clear();
            messagebar.focus();
            messages.addMessage({
              text: text,
              avatar: data2[0].avatar,
              textFooter: data2[0].footer,
              name: data2[0].data,
              Footer: ''
            });
            if (responseInProgress)
              return;
          }
        }, function () {
          app.preloader.hide();
          app.dialog.alert('Нет подключения к интернету');
        }, 'json');
      });
    }
  },
  id: '6d33819bdf',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== 'undefined' && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else
            return val;
        } else
          return '';
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '\r\n<div class="page">\r\n  <div class="navbar">\r\n    <div class="navbar-inner sliding">\r\n      <div class="left">\r\n        <a class="link nazad" @Click="backs">\r\n          <i  style="color:#073974;" class="icon icon-back"></i>\r\n        </a>\r\n      </div>\r\n      <div class="title"><font size="3%">';
      r += Template7Helpers.js.call(ctx_1, 'return this.$route.query.title', {
        hash: {},
        data: data_1 || {},
        fn: function empty() {
          return '';
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</font></div>\r\n    </div>\r\n  </div>\r\n  <div class="toolbar messagebar">\r\n    <div class="toolbar-inner">\r\n      <div class="messagebar-area">\r\n        <textarea class="resizable" id="ttteext" placeholder="Сообщение"></textarea>\r\n      </div><a class="link send-link" href="#">Отправить</a>\r\n    </div>\r\n  </div>\r\n  <div class="page-content messages-content">\r\n    <div class="messages">\r\n\r\n    </div>\r\n  </div>\r\n\r\n</div>\r\n';
      return r;
    }(this);
  },
  styleScoped: false
};
    